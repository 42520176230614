// Polygon Mumbai testnet
// export const DAFI_ADDRESS_POLYGON_MUMBAI =
//   "0xe61B223100676D6Ffa588C7c6720348DCAD3131F";

// export const NETWORK_DEMAND_POLYGON_MUMBAI =
//   "0xE14378f40379AB435c25f5DF5341F00eAB6065d1";

// export const STAKING_DATABASE_POLYGON_MUMBAI =
//   "0xeae3C9995fCcD6acad5c471241349C4913b82CBC";

// export const STAKING_MANAGER_POLYGON_MUMBAI =
//   "0xa37297F3e12BDbDF7d6377DDF916cdCaf3fD960e";

export const DAFI_ADDRESS_POLYGON_MUMBAI =
  "0xe61B223100676D6Ffa588C7c6720348DCAD3131F";

export const NETWORK_DEMAND_POLYGON_MUMBAI =
  "0xF487571686c6Dc5c905409572D28f58DEC8aB4DB";

export const STAKING_DATABASE_POLYGON_MUMBAI =
  "0xd2A871713509843c0dcbA26eF7885986EDD767df";

export const STAKING_MANAGER_POLYGON_MUMBAI =
  "0x49122e3E5C30577799aaA480D097Cb2940a8faF3";

// End

// App Mainnet
export const TOKEN_ADDRESS_POLYGON_MAINNET =
  "0x695FC8B80F344411F34bDbCb4E621aA69AdA384b";

export const NETWORK_DEMAND_POLYGON_MAINNET =
  "0x93957086FbdB1c8Bd056f0B0F704c5C59D652b29";

export const STAKING_DATABASE_POLYGON_MAINNET =
  "0x229A79c7a77cF342a0C7cc8158D9f00a41Df24dD";

export const STAKING_MANAGER_POLYGON_MAINNET =
  "0x9F4a1c2b2EB504886B5662978F8511eb70767c5f";
//

// App Mainnet 2
export const TOKEN_ADDRESS_ETHEREUM_MAINNET =
  "0x0335A7610D817aeCA1bEBbEfbd392ecC2eD587B8";

export const NETWORK_DEMAND_ETHEREUM_MAINNET =
  "0x8028CBdf9B31C10ba7EAEf9478f9a803B9369202";

export const STAKING_DATABASE_ETHEREUM_MAINNET =
  "0xDd0fE322E6f402642Afd951064c3a592b6c31993";

export const STAKING_MANAGER_ETHEREUM_MAINNET =
  "0x6064ceAaa4138Aa7d872b96e707dA4a4c3fD1619";
//

// export const API_BASE_URL = "http://172.18.1.29:5000";

export const API_BASE_URL = "https://nitro-server.superstaking.io";
export const API_DEV_BASE_URL = "http://54.86.186.137/api";
export const GRAPH_BASE_URL =
  "https://api.thegraph.com/subgraphs/name/pro123908";
