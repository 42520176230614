import {
  Box,
  makeStyles,
  Typography,
  Link,
  ClickAwayListener,
} from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { DiscordIconSvg, DocsIcon, TelegramIcon } from "../../assets";

const HelpDropdown = ({ borderRadius, value, setValue }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const [currentOption, setCurrentOption] = useState(0);
  const classes = useStyles({ showDropdown, borderRadius });

  console.log("CustomDropdown = ", value);

  const options = [
    {
      title: "Support",
      description: "Need support? Contact us",
      icon: DocsIcon,
      url: "mailto:support@dafiprotocol.io",
    },
    {
      title: "Telegram",
      description: "Join the telegram",
      icon: TelegramIcon,
      url: "https://t.me/nitroleaguegame",
    },
    {
      title: "Discord",
      description: "Join our discord",
      icon: DiscordIconSvg,
      url: "https://discord.com/invite/nitroleague",
    },
  ];

  useEffect(() => {
    setShowDropdown(false);
  }, [currentOption]);
  return (
    <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
      <Box className={classes.container}>
        <Box
          className={classes.itemShowContainer}
          onClick={() => setShowDropdown((val) => !val)}
        >
          {/* <Box className={classes.currentItem}>{options[value.index]}</Box> */}
          {/* <img src={ArrowDownSvg} /> */}
          <MoreHoriz className={classes.infoIcon} />
        </Box>

        {showDropdown && (
          <Box className={classes.itemsContainer}>
            {options.map((option, index) => (
              <Link
                className={classes.item}
                // onClick={() => {
                //   setCurrentOption(index);
                href={option.url}
                target="_blank"
                //   // setValue({ index, value: options[index] });
                // }}
              >
                <Box display="flex" alignItems="center" marginBottom="5px">
                  <Box className={classes.iconContainer}>
                    <img src={option.icon} />
                  </Box>

                  <Typography className={classes.title}>
                    {option.title}
                  </Typography>
                </Box>
                <Typography className={classes.description}>
                  {option.description}
                </Typography>
              </Link>
            ))}
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default HelpDropdown;

const useStyles = makeStyles((theme) => ({
  container: {
    background: "red",
    // height: "100%",
    width: "50px",
    // marginBottom: "3rem",

    background: theme.palette.themeColor1,

    border: "1px solid rgba(255, 255, 255, 0.58)",
    borderRadius: "10px",
    // border: "1px solid rgba(255, 255, 255, 0.08)",
    borderRadius: 10,
    cursor: "pointer",

    position: "relative",
  },

  currentItem: {
    fontSize: "1.2rem",
    lineHeight: "1.8rem",
    fontWeight: 400,
    color: "#fff",
  },

  item: {
    fontSize: "1.2rem",
    lineHeight: "1.8rem",
    fontWeight: 400,
    color: "#fff",
    padding: "1rem",

    "&:hover": {
      textDecoration: "none",
    },
  },

  itemsContainer: {
    position: "absolute",
    top: 60,
    width: "250px",
    right: 0,

    background: theme.palette.themeColor1,

    border: "1px solid rgba(255, 255, 255, 0.28)",

    zIndex: 10000,

    borderRadius: "25px",

    boxShadow:
      "inset 0px 2.36078px 10.6235px rgba(8, 5, 18, 0.2), inset 0px 0.590196px 23.6078px rgba(8, 5, 18, 0.2)",
    backdropFilter: "blur(40px)",

    padding: "0 20px",
  },

  itemShowContainer: {
    // padding: "0 2rem",

    height: "100%",
    width: "100%",
    display: "flex",

    alignItems: "center",
    justifyContent: "center",
  },
  infoIcon: {
    color: "#fff",
  },

  iconContainer: {
    height: 30,
    width: 30,
    backgroundColor: "#797290",
    display: "flex",

    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    borderRadius: 10,
  },

  title: {
    fontSize: "18px",
    lineHeight: "27px",
    fontWeight: 500,
    color: "#fff",
  },

  description: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 400,
    color: "#fff",
  },
}));
