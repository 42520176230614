import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { theme } from "../../theme";

const CustomBtn = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: "18px",
    fontWeight: 500,
    fontFamily: "Poppins",
    padding: "6px 12px",
    border: "1px solid",
    borderRadius: "10px",
    background: theme.palette.themeColor1,
    color: "#fff",

    height: "50px",
    "&:hover": {
      background: `rgba(${theme.palette.themeColor1RGBA},.7)`,

      boxShadow: "none",
    },
    "@media(max-width:550px)": {
      fontSize: "15px",
      fontWeight: 500,
    },
    "&:disabled": {
      backgroundColor: "rgba(51, 51, 51, 0.2)",
      color: "#121B2D",
      border: theme.palette.border,
    },
    // "&:active": {
    //   boxShadow: "none",
    //   backgroundColor: "#0062cc",
    //   borderColor: "#005cbf",
    // },
    // "&:focus": {
    //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    // },
  },
})(Button);

const BlockBtn = ({ label, handleClick, disabled }) => {
  return (
    <CustomBtn fullWidth disabled={disabled} onClick={() => handleClick()}>
      {label}
    </CustomBtn>
  );
};

BlockBtn.defaultProps = {
  handleClick: () => {},
  disabled: false,
};
export default BlockBtn;
