export const APP_NAME = "Nitro";
export const APP_DASHBOARD_TITLE = `${APP_NAME} Dashboard`;
export const APP_STAKE_PAGE_TITLE = `${APP_NAME} Super Staking`;

export const APP_SUPERPOOL_PAGE_TITLE = `${APP_NAME} Super Pool`;

export const APP_FONT_NAME = "Poppins";

export const APP_TOTAL_STAKED_TEXT = "Total Staked";

export const APP_YOUR_STAKED_TEXT = "Your Staked";
export const APP_FEES_COLLECTED_TEXT = "Fees Collected";
export const APP_REWARDS_TEXT = "Rewards";
export const APP_POTENTIAL_REWARDS_TEXT = "Potential";

export const APP_CARD_BG_COLOR = "#FFFFFF";

export const APP_CARD_BORDER = " 2px solid #DFE3EA";

export const APP_BTN_BACKGROUND =
  "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(107.63deg, #000000 -2.79%, #434343 103.77%)";

export const APP_BTN_BORDER = "2px solid #DFE3EA";

export const APP_BTN_BOX_SHADOW =
  "inset 0px -48.3961px 40.1333px -37.7725px rgba(255, 255, 255, 0.12)";

export const APP_BTN_BORDER_RADIUS = "10px";

export const APP_STAKING_MESSAGE =
  "You will be staking in the Polygon Super Pool.";

export const APP_STAKING_MESSAGE_ETH =
  "You will be staking in the Ethereum V2 Super Pool.";

export const APP_UNSTAKING_MESSAGE =
  "A 10% toll is deducted from your Nitro rewards when exiting a Pool. These tokens are then distributed among Super Stakers, boosting APY.";

export const APP_TOKEN_NAME = "NITRO";

export const TOP_FIVE_USERS_QUERY = `
{
  stakes(first:5,orderBy:amount, orderDirection:desc){
    id
    amount
  }
}
`;

export const TX_HISTORY_QUERY = `{
  stakehistories(first:1000,orderBy:time, orderDirection:desc){
    id
    staker
    amount
    tx_hash
    type
    time
  }
}`;

export const COIN_GECKO_BASE_URL = "https://api.coingecko.com/api/v3";

export const COIN_GECKO_COIN_ID = "nitro-league";

export const ETHERSCAN_API_KEY = "CH4P1UU8CNBDZD25WX9EXQY9XDGBZMG2VD";

export const ETHERSCAN_BASE_URL = "https://api.etherscan.io";

export const COIN_CIRC_SUPPLY = 43333333;

export const TWITTER_HANDLE = "@nitroleaguegame";

export const AMOUNT_FEE_RATIO = 0.9;
export const COUNT_PER_PAGE = 10;
