import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

import { useSelector } from "react-redux";

const MarketGraph = () => {
  const [series, setSeries] = useState([
    {
      name: "series1",
      data: [],
    },
  ]);

  const marketGraph = useSelector((state) => state.web3.data.marketGraph);

  console.log("marketGraph => ", marketGraph);

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      width: 30,
      type: "area",
      zoom: {
        enabled: false,
        type: "x",
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: "#121B2D",
            opacity: 0.4,
          },
          stroke: {
            color: "#121B2D",
            opacity: 0.4,
            width: 1,
          },
        },
      },
      toolbar: {
        show: false,
      },
      foreColor: "#121B2D",
      fontFamily: "Poppins",
    },

    colors: ["#121B2D"],

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [1, 2, 3, 4, 5, 6],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },

    legend: {
      show: false,
    },

    yaxis: {
      show: true,
    },

    // xaxis: {
    //   show: false,
    // },

    grid: {
      show: false,
    },

    noData: {
      text: "No Data Available",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "20px",
        fontFamily: undefined,
      },
    },
  });

  useEffect(() => {
    if (marketGraph?.prices.length > 0) {
      let timeStampArray = marketGraph?.prices.map((item) => item[0]);
      let priceArray = marketGraph?.prices.map((item) => item[1]);

      console.log("graph arrays => ", { timeStampArray, priceArray });
      setSeries([
        {
          name: "Price",
          data: priceArray,
        },
      ]);

      setOptions({
        ...options,

        xaxis: {
          type: "datetime",
          categories: timeStampArray,
          show: timeStampArray > 0,
        },

        yaxis: {
          show: priceArray.length > 0,
          decimalsInFloat: 6,
        },

        grid: {
          show: false,
        },
      });
    }
  }, [marketGraph]);
  return <Chart options={options} series={series} type="area" height={450} />;
};

export default MarketGraph;
